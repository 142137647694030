import React, { FC, useContext } from "react";
import { TenantContext } from "v3/context/tenant";
import { LocalizationContext } from "v3/context/localization";
import { PortalPublicPageWrapper } from "v3/components/portal/pageWrappers/PublicPageWrapper";
import { LoginForm } from "v3/components/forms/LoginForm";
import { parseTemplateAsString } from "@pepdirect/v3/helpers/localization";

export const SignInPage: FC = () => {
  const { tenant } = useContext(TenantContext);
  const { localization } = useContext(LocalizationContext);
  const {
    headingText = "Login to ${tenantName}",
    headingDefaultText = "your account",
  } = localization?.forms?.signIn || {};
  const title = parseTemplateAsString(headingText, {
    tenantName: tenant?.brandConfig.title || headingDefaultText,
  });

  return (
    <PortalPublicPageWrapper title={title}>
      <main>
        <LoginForm />
      </main>
    </PortalPublicPageWrapper>
  );
};

export default SignInPage;
